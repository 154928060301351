/* Default header styles for large screens (no changes needed here) */
.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: rgb(10, 5, 45); /* Sticky background color for large screens */
  z-index: 10;
  padding: 0px 30px;
  display: flex;
  font-family: "Poppins", sans-serif;
  justify-content: space-between;
  align-items: center;
}
/* Ensure logo and text are aligned horizontally */
.logo {
  display: flex;
  align-items: center; /* Vertically align logo and text */
  font-size: 26px;
  margin-left: 22px;
}

.logo img {
  width: 40px; /* Adjust width of the logo image */
  height: 40px; /* Adjust height of the logo image */
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px; /* Adjust margin between logo and text */
}

.logo p {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: white; /* Ensure text color is white */
}


/* Nav link styles (default for larger screens) */
.nav-link-title {
  font-size: 21px;
  margin-left: 5px;
  margin-right: 5px;
  color: white;
  text-decoration: none;
  padding: 5px 0;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.nav-links-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 35px;
  margin: 0 20px;
}

/* Show nav links on larger screens */
.nav-link {
  font-size: 21px;
  margin-left: 5px;
  margin-right: 5px;
  color: white;
  text-decoration: none;
  padding: 5px 0;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.nav-link:hover {
  color: white;
  border-bottom: 2px solid white;
}

.nav-link.active {
  color: white;
  border-bottom: 2px solid white;
}

.sidebar {
  display: none;
}

/* Media Query for Small Screens (Mobile/Tablets) */
@media (max-width: 768px) {
  .header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: rgb(10, 5, 45);
    z-index: 10;
    /* padding: 0px 30px; */
    display: flex;
    font-family: "Poppins", sans-serif;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    font-size: 22px; /* Adjust logo size for small screens */
    margin-left: 10px; /* Add a little margin on the left */
    align-items: flex-start; /* Align logo with top (same line as the sidebar) */
    margin-top: 10px; /* Add a little margin to the top for alignment */
  }

  .logo img {
    width: 35px;
    height: 35px; /* Adjust logo image size for smaller screens */
  }

  /* Hide the navigation links and show the drawer icon */
  .nav-links-container {
    display: none;
  }

  /* Display the hamburger menu icon */
  .sidebar {
    display: block;
    font-size: 26px;
    color: white;
    margin-right: 10px;
    cursor: pointer;
    position: absolute; /* Position icon to the top right */
    top: 20px; /* Adjust top margin */
    display: flex;
    justify-content: flex-end;
  }

  /* Position the header content closer to the left and right */
  .header {
    padding: 0 20px;
  }

  /* Adjust drawer and nav link styles in the drawer */
  .ant-drawer-body {
    background-color: white !important;
  }

  .ant-drawer .ant-drawer-header {
    background-color: white !important;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-drawer .ant-drawer-content-wrapper {
    background-color: white !important;
  }

  .ant-drawer .ant-drawer-content {
    padding: 20px;
  }

  .ant-drawer .ant-drawer-close {
    color: black;
  }

  .ant-drawer .nav-link {
    font-size: 18px;
    display: block;
    padding: 10px 0;
    color: #333;
    text-decoration: none;
  }

  .ant-drawer .nav-link:hover {
    color: #1890ff;
  }
}

/* Hide the VitaWave text on small screens */
@media (max-width: 768px) {
  .logo p {
    display: none;
  }
}


/* Additional Media Query for Very Small Screens (Small Phones) */
@media (max-width: 576px) {
  .header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: rgb(10, 5, 45);
    z-index: 10;
    /* padding: 0px 30px; */
    display: flex;
    font-family: "Poppins", sans-serif;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    font-size: 18px; /* Smaller logo font size */
    margin-left: -7px; /* Less margin for smaller screens */
    align-items: flex-start; /* Align logo with top */
    margin-top: -30px; /* Align with top margin */
  }

  .logo img {
    width: 30px;
    height: 30px; /* Further reduce logo size */
  }

  .nav-link {
    font-size: 16px; /* Smaller nav link font size */
    margin-left: 15px;
    margin-right: 15px;
  }

  /* Adjust the drawer icon position and size for very small screens */
  .sidebar {
    font-size: 24px;
    top: -7px; /* Adjust the top margin */
    /* Adjust the right margin */
  }
}

/* Custom styling for Drawer */
.ant-drawer-body {
  background-color: white !important;
}

.ant-drawer .ant-drawer-header {
  background-color: white !important;
  border-bottom: 1px solid #f0f0f0;
}

.ant-drawer .ant-drawer-content-wrapper {
  background-color: white !important;
}

.ant-drawer .ant-drawer-content {
  padding: 20px;
}

.ant-drawer .ant-drawer-close {
  color: black;
}

/* Styling for drawer links */
.ant-drawer .nav-link {
  font-size: 18px;
  display: block;
  padding: 10px 0;
  color: #333;
  text-decoration: none;
}

.ant-drawer .nav-link:hover {
  color: #1890ff;
}
