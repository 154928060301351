/* General Body and Page Styling */
body {
  background-color: white;
  margin: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden; /* Prevent horizontal scroll due to animations */
}

.coll-text{
  font-size: 2.2rem;
  padding: 25px;
    display: flex;
    justify-content: center;
}

/* Hero Section Styling */
.hero-section {
  position: relative;
  width: 100%;
  height: 80vh; /* Adjust this height based on your design needs */
  background-color: white;
  overflow: hidden; /* Prevent overflow in smaller containers */
  animation: slideInLeft 1s ease-out; /* Slide-in from left animation */
}

.hero-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-image-container video {
  object-fit: cover; /* Ensures the video covers the container without distortion */
  width: 100%;
  height: 100%;
}

/* Hero Text Styling */
.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 1.5rem;
  z-index: 10;
}

.hero-text h1 {
  font-size: 2rem; /* Reduced font size for small screens */
  margin-bottom: 1rem;
}

.hero-text p {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.hero-text p strong {
  font-weight: bold;
}

/* Hero Section Responsiveness */
@media (max-width: 768px) {
  .hero-section {
    height: 450px;
  }

  .hero-text {
    font-size: 1.2rem;
    top: 40%;
  }

  .hero-text p:first-of-type { /* Hide only the description paragraph on smaller screens */
    display: none;
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 210px;
  }

  .hero-text {
    font-size: 1rem;
    top: 35%;
  }

  .hero-image-container video {
    height: auto; /* Let the video adjust to its container */
  }

  .hero-text h1 {
    font-size: 1.5rem; /* Further reduce font size */
  }

  .hero-text p:first-of-type { /* Hide only the description paragraph on smaller screens */
    display: none;
  }
}

/* Hero Image Hover Effect */
.hero-image {
  width: 100%;
  height: auto;
  max-height: 350px;
  object-fit: contain;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out;
}

.hero-image:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Slide-in Animations */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

/* Info Section and Contrast Styles */
.info-section, .info-section-contrast {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 5%;
  z-index: 1;
  min-height: 200px;
  animation: slideIn 1s ease-out forwards;
}

.info-section {
  background-color: white;
}

.info-section-contrast {
  background-color: rgb(10, 5, 33);
}

.col-text, .col-text-contrast {
  width: 50%;
  font-size: 1.3rem;
  padding: 80px 90px 20px 60px;
  animation: slideInLeft 1s ease-out;
}

.col-text-contrast {
  color: white;
}

.image-card-seen {
  width: 100%;                     /* Make the image fill the full width of the container */
  height: 150px;                   /* Fixed height for the image */
  background-image: url('../../assets/images/boulder.png');  /* Background image */
  background-size: cover;         /* Ensure the image covers the entire container */
  background-position: center;    /* Center the image */
  border-radius: 10px;             /* Adds rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}


/* Image Card Styling */
.image-card, .detail-card {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: slideInRight 1s ease-out;
}

/* Detail Image Styling */
.detail-image {
  width: 100%;
  height: 280px;
  max-height: 350px;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out;
}

/* Image Card Hover Effect */
.detail-image:hover {
  transform: translateY(-10px); /* Move the image slightly upwards on hover */
}

.detail-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Dark overlay */
  z-index: 1;
  transition: background-color 0.3s ease-in-out;
}

.detail-image:hover::before {
  background-color: rgba(0, 0, 0, 0.4);
}

.card-content {
  position: relative;
  z-index: 2;
  padding: 16px;
  text-align: center;
}

.card-content h3 {
  font-size: 18px;
  margin-bottom: 8px;
  color: #333;
}

.card-content p {
  font-size: 14px;
  color: #666;
}

/* Button Styling */
button {
  background-color: rgb(10, 5, 33);
  color: white;
  border: 2px solid rgb(10, 5, 33);
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

button:hover {
  background-color: white;
  color: rgb(10, 5, 33);
  border-color: rgb(10, 5, 33);
}

.button-contrast {
  color: #1e3a8a;
  border: 2px solid white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.button-contrast:hover {
  background-color: rgb(10, 5, 33);
  color: white;
  border-color: white;
}

/* Small Screen Adjustments */
@media (max-width: 768px) {
  /* Info Section and Text */
  .col-text, .col-text-contrast {
    width: 100%; /* Full width for smaller screens */
    padding: 40px 20px; /* Adjust padding */
    max-height: 450px; /* Set max height */
    overflow: hidden; /* Prevent overflow */
    text-overflow: ellipsis; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  /* Add ellipsis effect for paragraphs in small screens */
  .col-text p, .col-text-contrast p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Position the "Learn More" button at the bottom-right */
  .col-text button, .col-text-contrast button {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .button-contrast {
    padding: 8px 18px; /* Adjust padding */
  }

  /* Adjust image card */
  .image-card {
    max-height: 450px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 15px;
  }

  /* Hero Image Adjustments */
  .hero-image {
    max-height: 250px;
    object-fit: cover;
  }

  /* Hero Text Adjustments */
  .hero-text {
    font-size: 1rem;
    top: 35%;
  }

  .hero-text h1 {
    font-size: 1.5rem;
  }

  .hero-text p:first-of-type {
    display: none; /* Hide first paragraph on smaller screens */
  }
}

@media (max-width: 480px) {
  /* Info Section Card - Adjustments for mobile screens */
  .col-text, .col-text-contrast {
    padding: 30px 15px; /* Adjust padding for mobile */
    font-size: 1rem;
    max-height: 450px;
  }

  /* Button Positioning for Small Screens - Link Style */
  .col-text button, .col-text-contrast button {
    position: absolute;
    bottom: 7px;
    right: 10px;
    background: transparent;  /* Make the button background transparent */
    color: rgb(10, 5, 33);     /* Set the text color to match your theme (dark color) */
    border: none;             /* Remove the border */
    text-decoration: underline;  /* Underline the text like a link */
    font-size: 14px;            /* Adjust font size */
    padding: 0;                /* No padding since it's a link style */
    cursor: pointer;          /* Ensure the cursor changes to a pointer */
    transition: color 0.3s, text-decoration 0.3s; /* Add smooth transition for hover effect */
  }

  /* Hover Effect for Link-Style Button */
  .col-text button:hover, .col-text-contrast button:hover {
    color: rgb(0, 50, 100);  /* Change color on hover (adjust to your theme) */
    text-decoration: none;   /* Remove underline on hover */
  }

  /* Image Card Adjustment for Mobile */
  .image-card {
    max-height: 400px;
    width: 100%;
    padding-top: 25px;
  }

  /* Hero Text Font Size for Mobile */
  .hero-text h1 {
    font-size: 1.3rem;
  }

  .coll-text{
    font-size: 2rem;
    padding: 25px;
  }

  .hero-text p {
    font-size: 1rem;
  }

  /* Adjust button size for smaller screens */
  .button-contrast {
    padding: 7px 15px;
  }
}

/* Slider Section (General) */
.Slide-section {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.slide-section {
  width: 100%;
  max-width: 1500px;
  padding: 0 20px 0 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 5px;
}

.slide-heading {
  color: black;
  text-align: center;
}

.slide-card-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-card {
  flex: 1;
  width: 200px;
  height: 200px;
  max-width: 480px;
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin: 10px;
}

.slide-image {
  width: 100%;
  max-width: 450px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

.slide-card-content h3 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.slide-card-content p {
  font-size: 1rem;
  color: #555;
}

.slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-dots {
  bottom: -20px;
}

.slick-prev, .slick-next {
  z-index: 10;
}

/* As Seen In Section */
.as-seen-in-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.as-seen-in-content h3 {
  margin-bottom: 20px;
}

.as-seen-in-content ul {
  list-style: none;
  padding: 0;
  padding-top: 10px;
}

.as-seen-in-content li {
  margin: 5px 0;
}

/* Ensure the slide section is centered on smaller screens */
@media (max-width: 768px) {
  .slide-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px; /* Ensure some padding on the sides */
  }

  .slide-card {
    width: 100%; /* Make sure the card takes full width in the container */
    max-width: 300px; /* Set a max-width for the image */
    margin: 0 auto; /* Center the slide card */
  }

  .slide-image {
    width: 100%; /* Make the image responsive */
    height: auto;
  }

  /* Optional: Adjust the slide heading font size for smaller screens */
  .slide-heading {
    font-size: 1.5rem;
  }

  /* Optional: Adjust the content padding */
  .slide-card-content h3 {
    font-size: 1.2rem;
  }

  .slide-card-content p {
    font-size: 1rem;
  }
}

/* Further adjustments for very small screens (480px and below) */
@media (max-width: 480px) {
  .slide-section {
    padding: 15px 10px; /* Further reduce padding on very small screens */
  }

  .hero-text h1 {
    font-size: 1rem;
    padding-top: 60px;
  }

  .slide-card {
    max-width: 280px; /* Slightly smaller card size for mobile */
    height: 200px;
  }

  .slide-heading {
    font-size: 1.3rem; /* Smaller heading on mobile */
  }

  .slide-card-content h3 {
    font-size: 1.1rem;
  }

  .slide-card-content p {
    font-size: 0.9rem;
  }
}
