/* ======================== Contact US ======================== */
.contact-container {
  padding-top: 100px; /* Increase this value for more space */
  padding: 50px;
  background-color: #020229;
  display: flex;
  flex-wrap: wrap; /* Allow wrapping of content on smaller screens */
  justify-content: center; /* Center content horizontally */
  align-items: flex-start;
  width: 100%; /* Ensure container takes full width */
}

.contact-header {
  margin-top: 50px;
  flex: 1 1 100%; /* Make it take full width on smaller screens */
  padding-right: 20px;
  text-align: center; /* Center the header text */
}

.contact-header h2 {
  font-size: 36px;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  color: white;
  margin-bottom: 20px;
}

.contact-header p {
  font-size: 18px;
  color: white;
  margin-bottom: 30px;
}

.contact-form {
  margin-top: 50px;
  flex: 1 1 100%; /* Make it take full width on smaller screens */
  max-width: 600px; /* Limit the width of the form */
  width: 100%; /* Ensure it takes full width */
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form .form-group label {
  display: block;
  font-size: 16px;
  color: #333;
}

.contact-form .form-group input,
.contact-form .form-group select,
.contact-form .form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.contact-form .form-group textarea {
  height: 150px; /* Adjust for message box */
  resize: vertical;
}

.contact-form input[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #053246;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form input[type="submit"]:hover {
  background-color: #024a92;
}

/* =========================== Responsive Layout ============================== */

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column; /* Stack header and form vertically */
    padding: 20px; /* Reduce padding */
    width: 100%; /* Ensure container takes full width */
  }

  .contact-header {
    text-align: center;
    flex: 1;
    padding-right: 0;
    margin-bottom: 30px;
  }

  .contact-form {
    margin-top: 20px;
    max-width: 100%; /* Full width on small screens */
    width: 100%; /* Full width */
    padding: 20px; /* Reduced padding for smaller screens */
  }

  .contact-form .form-group label {
    font-size: 14px; /* Smaller label font size */
  }

  .contact-form .form-group input,
  .contact-form .form-group select,
  .contact-form .form-group textarea {
    font-size: 14px; /* Smaller input font size */
    padding: 10px; /* Reduced padding for smaller screens */
  }

  .contact-form input[type="submit"] {
    font-size: 16px; /* Slightly smaller submit button font */
    padding: 10px; /* Reduced padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .contact-header h2 {
    font-size: 28px; /* Smaller header size */
  }

  .contact-header p {
    font-size: 16px; /* Smaller description text */
  }

  .contact-form .form-group label {
    font-size: 12px; /* Very small label font for tiny screens */
  }

  .contact-form .form-group input,
  .contact-form .form-group select,
  .contact-form .form-group textarea {
    font-size: 12px; /* Adjust input font size for very small screens */
    padding: 8px; /* Reduced padding */
  }

  .contact-form input[type="submit"] {
    font-size: 14px; /* Smaller submit button text */
    padding: 8px; /* Reduced padding */
  }
}
