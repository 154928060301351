/* Blog Application Styles */
.Blog-Application-content {
  background-color: #f4f4f4;
  position: relative;
  z-index: 1;
  padding: 150px 20px;
}

.Blog-background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.4;
}

.Blog-background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 12, 27, 0.7);
  z-index: -1;
}

.Blog-hero-heading {
  padding: 0 20px 20px 30px;
  font-size: 2.5rem;
  text-align: center;
  color: white;
  margin: 50px 0;
  font-weight: bold;
}


.Blog-article-section {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

/* Increase font size of all <p> tags inside .Blog-Application-content */
.Blog-Application-content p {
  color: #333;
  line-height: 1.8;
  margin: 10px 0;
}

/* Increase the font size of the Card title */
.ant-card-head-title {
  font-size: 28px; /* Set the font size to 28px */
  font-weight: bold; /* Optional: Make the title bold */
}

.Blog-Application-content strong {
  color: #333;
  line-height: 1.8;
  margin: 10px 0;
}

.blog-title-image{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 12px 25px 70px 25px;
  max-width: 800px;
}

.News-card{
  padding: 12px 35px 10px 35px;
}

.news-text p{
  font-size: 1.2rem;
  padding-top: 20px;
}

.news-reference p{
  font-size: 0.8rem;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .Blog-hero-heading {
    font-size: 2.0rem;
  }
  .Blog-Application-content p {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .Blog-hero-heading {
    font-size: 2rem;
  }
  .Blog-Application-content p {
    font-size: 1rem;
  }
}


/* Adjust the image width on smaller screens */
@media (max-width: 768px) {
  .blog-title-image {
    width: 70%; /* Increase width for medium screens */
  }
}

@media (max-width: 480px) {
  .blog-title-image {
    width: 90%; /* Further increase width for smaller screens */
  }
}
